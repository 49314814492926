import React, { useContext, useState } from "react";
import { Avatar, Button, Card, Input, Layout, Menu, theme, } from "antd";
import { CloseOutlined, SearchOutlined, } from "@ant-design/icons";
import { ReactComponent as DashboardIcon } from "../../images/dashboard.svg";
import { ReactComponent as LayersIcon } from "../../images/layers-three.svg";
import { ReactComponent as UsersIcon } from "../../images/users.svg";
import { ReactComponent as HelpIcon } from "../../images/help.svg";
import { ReactComponent as SettingsIcons } from "../../images/settings.svg";
import { ReactComponent as LogoutIcon } from "../../images/logout.svg";
import { AuthContext } from "../../context/AuthContext";
import "./style.css";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";

import { useNotificationContext } from "../../context/NotificationContext";
function getItem(label, key, icon, children) {
    return {
        key,
        icon,
        children,
        label,
    };
}

const MainLayout = (props) => {
    const { user, logout } = useContext(AuthContext);
    const { setError, setSuccess, setWrning, setInfo } = useNotificationContext()
    const navigation = useNavigate();
    // const [collapsed, setCollapsed] = useState(false);
    const { token } = theme.useToken();

    const items = [
        getItem(
            <NavLink to="/dashboard">Tableau de bord</NavLink>,
            "sub1",
            <DashboardIcon width={24} height={24} />
        ),
        getItem(
            <NavLink to="/remboursements">Mes projets</NavLink>,
            "sub2",
            <LayersIcon width={24} height={24} />,
            null
        ),
        // getItem(
        //     <NavLink to="/investments">Mes actions RSE</NavLink>,
        //     "9",
        //     <LayersIcon width={24} height={24} />,
        //     null
        // ),
        // getItem(
        //     <NavLink to="/chat-list">Mes échanges</NavLink>,
        //     "10",
        //     <UsersIcon width={24} height={24} />,
        //     null
        // ),
    ];

    // const admin = getItem(
    //     <NavLink to="/admin">Administrateur</NavLink>,
    //     "admin",
    //     <UserOutlined style={{ fontSize: 20, color: "#667085" }} />,
    //     null
    // )
    // if (user?.isAdmin) {
    //     items.push(admin)
    // }

    const onLogout = async () => {
        try {
            // Send the data to the backend
            const config = {
                method: "POST",
                url: "/auth/logout",
                data: {
                    userId: user.id
                }
            };
            const response = await axios(config);
            if (response.data.message) {

                logout(() => {
                    navigation("/login", { replace: true });
                    window.location.reload()
                });
            } else {
                console.log(response.data.error);
            }
        } catch (e) {
            console.log(e.message);
            setError({ message: e.message })
        }
    };


    return (
        <Layout style={{ minHeight: "100vh" }}>
            <Layout.Sider
                width={253}
                theme="light"
                style={{
                    overflow: "auto",
                    height: "100vh",
                    position: "fixed",
                    left: 0,
                    top: 0,
                    bottom: 0,
                }}
            // collapsible
            // collapsed={collapsed}
            // onCollapse={(value) => setCollapsed(value)}
            >
                <div className="sider-logo">
                    <img width={70} src="/logo.png" alt="" />
                </div>
                {/* <div style={{ padding: "0 10px 16px" }}>
                    <Input
                        size="large"
                        prefix={<SearchOutlined />}
                        placeholder="Recherche"
                    />
                </div> */}
                <Menu
                    defaultSelectedKeys={["1"]}
                    mode="vertical"
                    items={items}
                />
                <div
                    style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: token.colorBgBase,
                    }}
                >
                    <Menu
                        defaultSelectedKeys={["1"]}
                        mode="vertical"
                        items={[
                            getItem(
                                "Support",
                                "111",
                                <HelpIcon width={24} height={24} />
                            ),
                            getItem(
                                <NavLink to="/settings">Paramètres</NavLink>,
                                "112",
                                <SettingsIcons width={24} height={24} />
                            ),
                        ]}
                    />
                    {/* <DismissSection>
                        <p style={{ marginTop: 0, fontWeight: "bold" }}>Free Plan</p>
                        <p style={{ color: "#475467" }}>Lorem ipsum dolor sit amet consectetur. Eu odio venenatis arcu.</p>
                        <Progress
                            strokeColor={token.colorPrimary}
                            percent={50}
                            showInfo={false}
                        />
                    </DismissSection> */}
                    <div className="menu-user-area">
                        <Card.Meta
                            className="meta-settings"
                            // avatar={
                            //     <Avatar
                            //         size={40}
                            //         src={user?.avatarUrl ?? "https://xsgames.co/randomusers/avatar.php?g=pixel"}
                            //     />
                            // }
                            title={user?.firstName ? user?.firstName + " " + user?.lastName : user?.username}
                            description={user?.email ?? ""}
                        />
                        <Button
                            type="link"
                            className="logout-button"
                            onClick={onLogout}
                        >
                            <LogoutIcon width={15} height={15} />
                        </Button>
                    </div>
                </div>
            </Layout.Sider>
            <Layout style={{ marginLeft: 253 }}>
                <Layout.Content className="main-body">
                    {props.children}
                </Layout.Content>
                {/* <Layout.Footer>Footer</Layout.Footer> */}
            </Layout>
        </Layout>
    );
};
export { MainLayout };

const DismissSection = ({ children, onDismiss, onOk }) => {
    const [dismiss, setDismiss] = useState(false);
    const onDismissClick = () => {
        setDismiss(true);
        onDismiss && onDismiss();
    };
    const onOkClick = () => {
        setDismiss(true);
        onOk && onOk();
    };
    return dismiss ? null : (
        <div className="dismiss-section">
            <Button
                type="link"
                className="close-dismiss"
                onClick={onDismissClick}
            >
                <CloseOutlined />
            </Button>
            {children}
            <Button
                style={{ color: "#475467" }}
                type="link"
                onClick={onDismissClick}
            >
                Dismiss
            </Button>
            <Button type="link" onClick={onOkClick}>
                Upgrade
            </Button>
        </div>
    );
};
