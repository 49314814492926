import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Col, DatePicker, Divider, Form, Input, Modal, Row, Select, Skeleton, Table, Tabs, Typography, Upload, theme, } from "antd";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { numberFormatter } from "../AdminLayout";
import { ReactComponent as AddButtonIcon } from "../../../images/add-btn.svg";
import { t } from "i18next";
import { StatisticsCard } from "../Components/StatisticsCard";
import EncorsProjects from "../EncorsProjects";
import PassProjects from "../PassProjects";
import ActionsList from "../ActionsList";
import Echanges from "../Echanges";
import Documents from "../Documents";
import InvoiceForm from "../InvoiceForm";
import AddInvoiceModal from "../AddInvoiceModal";
import { useEnterprisesContext } from "../../../context/admin/EnterprisesContext";
import { useLocation, useParams } from "react-router-dom";
import { useProjectContext } from "../../../context/ProjectContext";
import { useActionContext } from "../../../context/ActionContext";
import Filters from "../Components/Filters";
import { useDocumentContext } from "../../../context/admin/DocumentContext";
import { useAuthContext } from "../../../context/AuthContext";
import { formatDate } from "../../../functions";
import axios from "axios";

const deviceTypes = [
    { label: "Smartphones", value: "phone" },
    { label: "Tablettes", value: "tab" },
    { label: "Clés USB", value: "usb" },
    { label: "Disques durs", value: "drive" }
]

const EnterpriseDetail = () => {
    const params = useParams()
    const { enterprise, enterprises, selectEnterprise } = useEnterprisesContext()
    const { accessToken, user } = useAuthContext()
    const { projects } = useProjectContext()
    const { pendingAssociations } = useActionContext()
    const { pendingDocs } = useDocumentContext()
    const { state } = useLocation();
    const [open, openModal] = useState(false);
    const [currentTab, selectTab] = useState(state?.tab ?? "1");
    const [selected, setSelected] = useState(null);
    const [currentProject, setCurrentProjectId] = useState(null);
    const [invoices, setInvoices] = useState({ open: [], passed: [] });
    const [invoicesRefund, setInvoicesRefund] = useState(0);
    const [filterData, setFilterData] = useState([])
    const [totalPassedRefund, setTotalPassedRefund] = useState(0);
    const [investTotal, setInvestTotal] = useState(0);
    const [downloading, setDowloading] = useState(false);

    const { token } = theme.useToken();
    const [form] = Form.useForm();

    useEffect(() => {
        if (!enterprise && enterprises.length) {
            const filtered = enterprises.filter(e => {
                return e.id.toString() === params.id
            })
            selectEnterprise(filtered[0])
            setSelected(filtered[0])
        }
    }, [enterprises])

    useEffect(() => {
        if (enterprise) {
            setSelected(enterprise)
        }
    }, [enterprise])
    useEffect(() => {
        setFilterData([...invoices.open])
    }, [invoices.open])

    useEffect(() => {
        if (projects.length && selected) {
            const userBaseFiltered = projects.filter(p => p.userId === selected.userId)
            const currentBaseFilter = userBaseFiltered.filter(p => p.active)
            console.log(currentBaseFilter);
            const open = currentBaseFilter.length ? currentBaseFilter[0] : null
            setCurrentProjectId(open?.id)
            const openInvoices = selected.invoices?.filter(inv => inv.projectId === open?.id.toString())
            const passed = userBaseFiltered.slice(1)
            let passedInvoices = []
            for (const p of passed) {
                passedInvoices = passedInvoices.concat(selected.invoices?.filter(inv => inv.projectId?.toString() === p?.id.toString()))
            }
            setInvoices(prev => ({ ...prev, open: openInvoices, passed: passedInvoices }))
            const totalInvoices = openInvoices.concat(passedInvoices)
            let refund = 0
            for (const i of totalInvoices) {
                refund += i.refund > 0 ? i.refund : 0
            }
            setInvoicesRefund(refund)

            let openRefund = 0
            for (const i of openInvoices) {
                openRefund += i.refund > 0 ? i.refund : 0
            }
            setTotalPassedRefund(openRefund)
            let ivnst = 0
            console.log(open);
            for (const i of open?.investments) {
                ivnst += i.amount
            }
            setInvestTotal(ivnst)
        }
    }, [selected, projects])

    const invoiceInfo = {
        companyId: selected?.id,
        userId: selected?.userId,
        projectId: currentProject,
        paramsId: params.id
    }
    const handleDownloadContract = async () => {
        try {
            const config = {
                method: "GET",
                url: `/profile/contract?companyId=${selected.id}`,
                headers: {
                    "content-type": "multipart/form-data",
                    "x-access-token": accessToken ?? user.accessToken,
                },
                responseType: 'arraybuffer'
            };
            setDowloading(true)
            const response = await axios(config);
            const blob = new Blob([response.data], { type: "application/pdf" });
            const a = document.createElement("a");
            a.href = window.URL.createObjectURL(blob);
            a.download = "Mandat Sience signé";
            a.click();
        } catch (error) {
            console.log(error.message);
        } finally {
            setDowloading(false);
        }
    };
    return (
        <>
            <Row style={{ marginBottom: 24 }}>
                <Col style={{ flex: 1 }}>
                    <Typography.Title
                        style={{ margin: 0, color: "#101828" }}
                        level={2}
                    >
                        {selected ? selected?.companyName + (selected?.codeAPE ? ` (${selected?.codeAPE})` : "") : <Skeleton.Button active size="default" paragraph={{ rows: 0 }} />}
                    </Typography.Title>
                    <Typography.Title
                        level={5}
                        style={{ color: "#475467", margin: 0, fontWeight: 400 }}
                    >
                        {selected ? "Inscription le " + (selected?.createdAt ? formatDate(selected?.createdAt) : null) : <Skeleton active size="default" paragraph={{ rows: 0 }} />}
                    </Typography.Title>
                </Col>
                <Col>
                    <div
                        className="company-worth-box"
                        style={{ borderColor: token.colorPrimary }}
                    >
                        {numberFormatter(invoicesRefund)}
                    </div>
                </Col>
            </Row>
            {["2"].includes(currentTab) ? (
                <Row gutter={20}>
                    <Col xs={24} md={8}>
                        <StatisticsCard label={"Montant remboursé"} value={totalPassedRefund} format={true} />
                    </Col>
                    <Col xs={24} md={8}>
                        <StatisticsCard label={"Montant en action"} value={investTotal} format={true} />
                    </Col>
                    <Col xs={24} md={8}>
                        <StatisticsCard label={"Montant en banque"} value={totalPassedRefund - investTotal} format={true} />
                    </Col>
                </Row>
            ) : null}
            {["4"].includes(currentTab) ? (
                <Row gutter={20}>
                    <Col xs={24}>
                        <Typography.Paragraph style={{ fontWeight: 600 }}>
                            Démarrez un nouvel échange
                        </Typography.Paragraph>
                        <Form form={form} size="large">
                            <Row gutter={20}>
                                <Col xs={24} sm={12}>
                                    <Form.Item name="company">
                                        <Input placeholder="Référence du projet" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Form.Item name="license_no">
                                        <Input placeholder="Référence de la facture" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Form.Item name="phone_number">
                                        <Input placeholder="Choix du support" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Form.Item>
                                        <Button
                                            block
                                            type="link"
                                            htmlType="submit"
                                            style={{ backgroundColor: "#F3ECF4", height: 44 }}
                                            size="middle"
                                            onClick={() => openModal(2)}
                                        >
                                            Commencer un nouvel échange
                                        </Button>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}></Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            ) : null}
            <Row gutter={20}>
                <Col xs={24}>
                    {["1", "2"].includes(currentTab) ? (
                        <div>
                            {/* <AddInvoiceModal open={open === 1} getEstimated={getEstimated} deviceTypes={deviceTypes} onCancel={() => openModal(false)} addDevice={addDevice} /> */}
                            <Filters
                                invoiceInfo={invoiceInfo}
                                setFilterData={setFilterData}
                                dataSource={currentTab.toString() === "1" ? invoices.open : currentTab.toString() === "1" ? invoices.passed : []}
                            />
                        </div>
                    ) : null}
                    <Modal
                        className="uploadImage"
                        width={680}
                        open={open === 3}
                        onCancel={() => openModal(false)}
                        okText={t("Valider")}
                        cancelButtonProps={{
                            style: {
                                display: "none",
                            },
                        }}
                        okButtonProps={{
                            block: true,
                            size: "large",
                            style: { margin: 0 },
                        }}
                    >
                        <Form form={form} layout="vertical" size="large">
                            {(values) => (
                                <>
                                    <Typography.Title
                                        level={4}
                                        style={{ margin: 0, fontSize: 18, marginBottom: 20 }}
                                    >
                                        Nouvelle association
                                    </Typography.Title>
                                    <Card
                                        style={{ marginBottom: 20 }}
                                        bodyStyle={{ padding: 0 }}
                                    >
                                        <Table
                                            pagination={false}
                                            columns={[
                                                {
                                                    key: "structure",
                                                    dataIndex: "structure",
                                                    title: "Structure",
                                                },
                                                {
                                                    key: "business_number",
                                                    dataIndex: "business_number",
                                                    title: "N°Siret",
                                                },
                                                {
                                                    key: "merchant",
                                                    dataIndex: "merchant",
                                                    title: "Mécénat",
                                                },
                                                {
                                                    key: "charity",
                                                    dataIndex: "charity",
                                                    title: "Charte signée",
                                                },
                                                {
                                                    key: "access",
                                                    dataIndex: "access",
                                                    title: "Accès",
                                                },
                                            ]}
                                            dataSource={[
                                                {
                                                    structure: "Asso nouvelle",
                                                    business_number: "123 456 789 00011",
                                                    merchant: "Oui",
                                                    charity: "Oui",
                                                    access: "Public",
                                                },
                                            ]}
                                        />
                                    </Card>
                                    <Card
                                        style={{ marginBottom: 20 }}
                                        bodyStyle={{ padding: 0 }}
                                    >
                                        <Table
                                            pagination={false}
                                            columns={[
                                                {
                                                    key: "reference",
                                                    dataIndex: "reference",
                                                    title: "Référent",
                                                },
                                                {
                                                    key: "contact_number",
                                                    dataIndex: "contact_number",
                                                    title: "N°Téléphone",
                                                },
                                                {
                                                    key: "email",
                                                    dataIndex: "email",
                                                    title: "Adresse email",
                                                },
                                            ]}
                                            dataSource={[
                                                {
                                                    reference: "Louis Dupont",
                                                    contact_number: "07.56.78.90.32",
                                                    email: "asso.nom@gmail.com",
                                                },
                                            ]}
                                        />
                                    </Card>
                                    <Form.Item label="Documents">
                                        <Button type="link">
                                            <u>Charte</u>
                                        </Button>
                                    </Form.Item>
                                    <Form.Item label="">
                                        <Button type="link">
                                            <u>RIB</u>
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form>
                    </Modal>

                    <Card bordered={false} bodyStyle={{ padding: "0px" }}>
                        <Tabs
                            defaultActiveKey={currentTab}
                            tabBarStyle={{ margin: 0, paddingLeft: 10 }}
                            onChange={(e) => { selectTab(e) }}
                            items={[
                                {
                                    key: "1",
                                    label: `Projets en cours`,
                                    children: (
                                        <EncorsProjects
                                            invoiceInfo={invoiceInfo}
                                            invoices={filterData}
                                        />
                                    ),
                                },
                                {
                                    key: "2",
                                    label: `Projets passés`,
                                    children: (
                                        <PassProjects invoiceInfo={invoiceInfo} invoices={[...invoices.passed]} />
                                    ),
                                },
                                {
                                    key: "3",
                                    label: (
                                        <>
                                            <span style={{ marginRight: 10 }}>Actions RSE</span>
                                            <Badge count={pendingAssociations[selected?.userId]} color="primary" />
                                            {/* <Badge count={pendingAssociations?.length} color="primary" /> */}
                                        </>
                                    ),
                                    children: (
                                        <ActionsList userId={selected?.userId ?? state?.userId} />
                                    ),
                                },
                                {
                                    key: "5",
                                    label: (
                                        <>
                                            <span style={{ marginRight: 10 }}>Documents</span>
                                            <Badge count={pendingDocs[selected?.userId]} color="primary" />
                                        </>
                                    ),
                                    children: (
                                        <Documents enterprise={selected} />
                                    ),
                                },
                            ]}
                        />
                    </Card>
                    {
                        ["5"].includes(currentTab) ? <>
                            <Divider />
                            <Card>
                                <Row>
                                    <Col>
                                        <Typography.Title level={4} className="heading">
                                            Mandat Sience signé
                                        </Typography.Title>
                                    </Col>
                                    <Col style={{ marginLeft: "auto", }}>
                                        <Button type="link" disabled={downloading} loading={downloading} onClick={handleDownloadContract} >Télécharger</Button>
                                    </Col>
                                </Row>
                            </Card>
                        </> : null
                    }
                </Col>
            </Row>
        </>
    );
};

export default EnterpriseDetail;
