import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  Link,
} from "react-router-dom";
import ProtectedRoute from "../../../ProtectedRoute";
import AdminLayout from "../AdminLayout";
import { AdminDashboard } from "../Dashboard";
import { AdminProvider } from "../../../context/admin/AdminContext";
import { EnterprisesProvider } from "../../../context/admin/EnterprisesContext";
import EnterpriseDetail from "../EnterpriseDetail";
import Associations from "../Associations";
import Distributers from "../Distributers";
import Investments from "../Investments";
import SettingsPage from "../SettingsPage";
// import RemboursementDetails from '../RemboursementDetails';
import Enterprises from "../Enterprises";
import Users from "../UsersArea/Users";
import AddUser from "../UsersArea/AddUser";
import { DocumentProvider } from "../../../context/admin/DocumentContext";
import { SearchProvider } from "../../../context/admin/SearchContext";
import { Button, Result } from "antd";
import NullPage from "../../Components/NullPage";
import InvoiceForm from "../InvoiceForm";

const AdminRouter = () => {
  return (
    <React.Fragment>
      <AdminProvider>
        <EnterprisesProvider>
          <DocumentProvider>
            <SearchProvider>
              <Routes>
                <Route exact path={"/"} element={<ProtectedRoute component={AdminLayout}></ProtectedRoute>}                >
                  <Route index element={<ProtectedRoute component={AdminDashboard}                      ></ProtectedRoute>}                  ></Route>
                  <Route exact path={"dashboard"} element={<ProtectedRoute component={AdminDashboard}                      ></ProtectedRoute>} />
                  <Route exact path={"enterprises"} element={<ProtectedRoute component={Enterprises}></ProtectedRoute>} />
                  <Route exact path={"enterprises/:id"} element={<ProtectedRoute component={EnterpriseDetail}                      ></ProtectedRoute>} />

                  {/* <Route exact path={"remboursements"} element={<ProtectedRoute component={Remboursements}></ProtectedRoute>} /> */}
                  {/* <Route exact path={"remboursements/:id"} element={<ProtectedRoute component={EnterpriseDetail}></ProtectedRoute>} /> */}

                  <Route exact path={"associations"} element={<ProtectedRoute component={Associations}></ProtectedRoute>} />
                  <Route exact path={"distributers"} element={<ProtectedRoute component={Distributers}></ProtectedRoute>} />
                  <Route exact path={"invoice"} element={<ProtectedRoute component={InvoiceForm}></ProtectedRoute>} />
                  <Route exact path={"settings"} element={<ProtectedRoute component={SettingsPage}></ProtectedRoute>} />
                  <Route exact path={"users"}>
                    <Route index element={<ProtectedRoute component={Users}></ProtectedRoute>}                    ></Route>
                    <Route exact path={"new"} element={<ProtectedRoute component={AddUser}></ProtectedRoute>}                    ></Route>
                    <Route exact path={":id"} element={<ProtectedRoute component={AddUser}></ProtectedRoute>}                    ></Route>
                  </Route>
                </Route>
                <Route exact path="/*" element={<NullPage />} />
              </Routes>
            </SearchProvider>
          </DocumentProvider>
        </EnterprisesProvider>
      </AdminProvider>
    </React.Fragment>
  );
};

export default AdminRouter;
