import React, { useEffect, useState } from "react";
import { Button, Col, ConfigProvider, DatePicker, Form, Input, Row, Select, Table, theme, } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const DataTable = ({ name, columns, dataSource, alterBg, noFilters, empty, statusList, dateFilter, handlePicker }) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [items, setItems] = useState(dataSource ?? []);
  const [search, setSearch] = useState({ "keyword": "", "status": "", "start": "", "end": "" });

  useEffect(() => {
    setItems(dataSource)
  }, [dataSource])

  useEffect(() => {
    filterDataSource()
  }, [search])

  const handleChange = v => {
    setSearch(p => ({ ...p, ...v }))
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const filterDataSource = () => {
    const { keyword, status, start, end } = search
    let filtered = []
    let startDate = ""
    let endDate = ""
    let check = ""
    let filteredByName = []
    if (keyword.length) {
      filteredByName = dataSource?.filter(item => item[name].toLowerCase().includes(keyword.toLowerCase()))
    }

    let filteredByStatus = []
    if (status.toString().length) {
      filteredByStatus = dataSource?.filter(item => status.toString() === item.status.toString())
    }

    let filteredByDate = []
    if (start !== "" || end !== "") {
      startDate = new Date(start);
      endDate = new Date(end);
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);
      filteredByDate = dataSource?.filter(item => {
        check = new Date(item.submitDate);
        check.setHours(0, 0, 0, 0);
        return check >= startDate && check <= endDate
      })
    }
    const combined = filteredByName.concat(filteredByStatus, filteredByDate)
    const unique = [];
    const seen = new Set();

    for (const obj of combined) {
      if (!seen.has(obj.id)) {
        seen.add(obj.id);
        unique.push(obj);
      }
    }
    filtered = unique.filter(item => {
      const dateToCheck = new Date(item.submitDate);
      dateToCheck.setHours(0, 0, 0, 0);
      if (keyword.length && status.length && start.length && end.length) {
        return item[name].toLowerCase().includes(keyword.toLowerCase()) && status.toString() === item.status.toString() && dateToCheck >= startDate && dateToCheck <= endDate
      } else if (keyword.length && status.length) {
        return item[name].toLowerCase().includes(keyword.toLowerCase()) && status.toString() === item.status.toString()
      } else if (keyword.length && start.length && end.length) {
        return item[name].toLowerCase().includes(keyword.toLowerCase()) && dateToCheck >= startDate && dateToCheck <= endDate
      } else if (status.length && start.length && end.length) {
        return status.toString() === item.status.toString() && dateToCheck >= startDate && dateToCheck <= endDate
      } else {
        return item
      }
    })
    if (keyword.length === 0 && status.length === 0 && start.length === 0 && end.length === 0) {
      filtered = dataSource ?? []
    }
    setItems([...filtered])
  }

  return (
    <>
      {!noFilters ? (
        <Form layout="vertical" form={form} name="searchForm" size="large">
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Form.Item name="search" label="Rechercher par nom">
                <Input
                  prefix={<SearchOutlined size={15} />}
                  placeholder="Rechercher"
                  name="keyword"
                  onChange={({ target }) => handleChange({ [target.name]: target.value })}
                />
              </Form.Item>
            </Col>
            <Col xs={12} md={4}>
              <Form.Item name="status" label="Statuts">
                <Select onChange={(value) => handleChange({ "status": value })}>
                  {statusList?.map((row) => (
                    <Select.Option key={row.value} value={row.value} >
                      <span
                        className="small-dot"
                        style={{ backgroundColor: row.color }}
                      />
                      {row.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {
              dateFilter ? <Col xs={12} md={8}>
                <Form.Item name="date" label=" " onChange={() => console.log("asfdsdf")}>
                  <DatePicker.RangePicker size="large" onChange={(dates, dateStrings) => {
                    handleChange({ start: dateStrings[0], end: dateStrings[1] })
                  }} />
                </Form.Item>
              </Col> : null
            }

            <Col
              xs={12}
              md={6}
              style={{ marginLeft: "auto", textAlign: "right" }}
            >
              <Form.Item label="&nbsp;">
                <Button htmlType="reset"
                  onClick={() => handleChange({ "keyword": "", "status": "", "start": "", "end": "" })}
                >Réinitialiser les filtres</Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : null}
      <ConfigProvider renderEmpty={() => (empty ? empty : undefined)}>
        <Table
          rowClassName={(record, index) =>
            alterBg
              ? index % 2 === 0
                ? "table-row-light"
                : "table-row-dark"
              : ""
          }
          pagination={{
            hideOnSinglePage: true,
            // position: [top, bottom],
            // defaultPageSize: 3
          }}
          // rowSelection={rowSelection}
          dataSource={items}
          columns={columns}
        />
      </ConfigProvider>
    </>
  );
};

export { DataTable };
