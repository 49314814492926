import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Image, Form, Typography, InputNumber, Input, DatePicker, Table, Select, Upload, message, Card, List } from 'antd';
import { DeleteOutlined, CloseOutlined, EditOutlined, EyeOutlined, FilterOutlined, InfoCircleFilled, PaperClipOutlined, PhoneFilled, PlusOutlined, SearchOutlined, SendOutlined, VideoCameraFilled, ExportOutlined, } from "@ant-design/icons";
import moment from "moment";
import { t } from "i18next";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ReactComponent as AddButtonIcon } from "../../../images/add-btn.svg";
import { useAdminContext } from '../../../context/admin/AdminContext';
import { useNotificationContext } from '../../../context/NotificationContext';
import { useEnterprisesContext } from '../../../context/admin/EnterprisesContext';
import { useProjectContext } from '../../../context/ProjectContext';
import { DataTable } from '../Components/DataTable';
import PDFViewerComp from '../Components/PDFViewerComp';
import { useLocation, useNavigate } from 'react-router-dom';
import { convertToGO, getOffsetDate } from '../../../functions';
import { domains } from '../../../constants';
import PDFViewerReact from '../Components/PDFViewerReact';
import DispalayPDF from '../Components/DispalayPDF';

dayjs.extend(customParseFormat);

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });



const server = domains.live

const deviceTypes = [
    { label: "Smartphones", value: "phone" },
    { label: "Tablettes", value: "tab" },
    { label: "Clés USB", value: "usb" },
    { label: "Disques durs", value: "drive" },
    { label: "Cartes mémoires", value: "card" }
]

const types = {
    phone: "phone",
    tab: "tab",
    usb: "usb",
    drive: "drive",
    card: "card"
}

const InvoiceForm = props => {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const { addInvoice } = useAdminContext()
    const { enterprise, enterprises, setEnterprises, selectEnterprise } = useEnterprisesContext()
    const { setError, setInfo } = useNotificationContext()
    const { projects, storeProjects } = useProjectContext()

    const [form] = Form.useForm();
    const [newDevice] = Form.useForm();
    const [openDeviceForm, setNewDevice] = useState(false);
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([])
    const [history, setHistory] = useState([])
    const [device, setDevice] = useState(-1)
    const [invoice, setInvoiceFile] = useState(null)
    const [oldInvoice, setOldInvoice] = useState(null)

    useEffect(() => {
        newDevice.setFieldsValue({ "unit": "go" })
    })

    useEffect(() => {
        if (!enterprise && enterprises.length) {
            const filtered = enterprises.filter(e => {
                return e.id.toString() === state.paramsId
            })
            selectEnterprise(filtered[0])
        }
    }, [enterprises])

    useEffect(() => {
        const vals = {
            title: state?.selectedInvoice?.title,
            file: state?.selectedInvoice?.filePath,
            supplier: state?.selectedInvoice?.supplier,
            devices: state?.selectedInvoice?.invoiceitems ?? [],
            userId: state?.userId
        }
        if (state?.selectedInvoice?.submitDate) {
            const momentDate = moment(state?.selectedInvoice?.submitDate).format("DD-MM-YYYY")
            vals.submitDate = dayjs(momentDate, 'DD-MM-YYYY')
        }

        setPreviewImage(state?.selectedInvoice?.filePath)
        setTitle(vals.title)
        form.setFieldsValue(vals)
        setOldInvoice(vals)
    }, [state?.selectedInvoice])

    const isMatched = (o, n) => {
        return JSON.stringify(o) === JSON.stringify(n) && n.file === previewImage
    }

    const catDates = {
        "phone": "07/01/2021",
        "tab": false,
        "usb": "02/01/2020",
        "drive": false,
        "card": "02/01/2020",
    }

    const getEstimated = () => {
        const { submitDate } = form.getFieldsValue(true)
        const { type, quantity, capacity, unit } = newDevice?.getFieldsValue(true)
        const invDate = getOffsetDate(submitDate)
        const catDate = catDates[type] ? getOffsetDate(catDates[type]) : null
        const isBefore = invDate <= catDate

        const capacityInGO = convertToGO(capacity, unit);
        let p = 0
        if (capacity > 0) {
            if (type === types.phone) {
                if (capacityInGO <= 0.135) {
                    p = isBefore ? 4 : 0.5
                } else if (capacityInGO > 0.135 && capacityInGO <= 2) {
                    p = isBefore ? 4 : 2.5
                } else if (capacityInGO > 2 && capacityInGO <= 8) {
                    p = 4
                } else if (capacityInGO > 8 && capacityInGO <= 16) {
                    p = 8
                } else if (capacityInGO > 16 && capacityInGO <= 32) {
                    p = 10
                } else if (capacityInGO > 32 && capacityInGO <= 64) {
                    p = 12
                } else if (capacityInGO > 64) {
                    p = 14
                }
            } else if (type === types.tab) {
                if (capacityInGO > 0 && capacityInGO <= 16) {
                    p = 8
                } else if (capacityInGO > 16 && capacityInGO <= 32) {
                    p = 10
                } else if (capacityInGO > 32 && capacityInGO <= 64) {
                    p = 12
                } else if (capacityInGO > 64) {
                    p = 14
                }
            } else if (type === types.usb) {
                if (capacityInGO > 0 && capacityInGO <= 2) {
                    p = isBefore ? 0.2 : 1
                } else if (capacityInGO > 2 && capacityInGO <= 4) {
                    p = isBefore ? 0.16 : 1
                } else if (capacityInGO > 4 && capacityInGO <= 8) {
                    p = isBefore ? 0.13 : 1
                } else if (capacityInGO > 8 && capacityInGO <= 16) {
                    p = isBefore ? 0.10 : 1.5
                } else if (capacityInGO > 16 && capacityInGO <= 32) {
                    p = isBefore ? 0.10 : 2
                } else if (capacityInGO > 32 && capacityInGO <= 64) {
                    p = isBefore ? 0.10 : 2.8
                } else if (capacityInGO > 64 && capacityInGO <= 128) {
                    p = isBefore ? 0.10 : 3.4
                } else if (capacityInGO > 128 && capacityInGO <= 256) {
                    p = isBefore ? 0.10 : 4
                } else if (capacityInGO > 256) {
                    p = isBefore ? 0.10 : 4.6
                }
            } else if (type === types.drive) {
                if (capacityInGO > 0 && capacityInGO <= 5000) {
                    p = 6
                } else if (capacityInGO > 5000 && capacityInGO <= 10000) {
                    p = 10
                } else if (capacityInGO > 10000) {
                    p = 15
                }
            } else if (type === types.card) {
                if (capacityInGO > 0 && capacityInGO <= 2) {
                    p = isBefore ? 0.09 : 1
                } else if (capacityInGO > 2 && capacityInGO <= 4) {
                    p = isBefore ? 0.08 : 1
                } else if (capacityInGO > 4 && capacityInGO <= 8) {
                    p = isBefore ? 0.07 : 1
                } else if (capacityInGO > 8 && capacityInGO <= 16) {
                    p = isBefore ? 0.06 : 1.5
                } else if (capacityInGO > 16 && capacityInGO <= 32) {
                    p = isBefore ? 0.06 : 2
                } else if (capacityInGO > 32 && capacityInGO <= 64) {
                    p = isBefore ? 0.06 : 2.8
                } else if (capacityInGO > 64 && capacityInGO <= 128) {
                    p = isBefore ? 0.06 : 3.4
                } else if (capacityInGO > 128 && capacityInGO <= 256) {
                    p = isBefore ? 0.06 : 4
                } else if (capacityInGO > 256) {
                    p = isBefore ? 0.06 : 4.6
                }
            }
            if (isBefore && (type === types.card || type === types.usb)) {
                p *= capacity
            }
        } else p = 0

        const val = type && quantity ? p * quantity : 0
        newDevice.setFieldsValue({ "estimated": parseFloat(val.toFixed(2)) })
    };

    const saveinvoice = async () => {
        const data = form.getFieldsValue(true)
        const matched = isMatched(oldInvoice, data)
        const values = { ...data }
        values.id = state?.selectedInvoice?.id
        const title = values.title && values.title !== ""
        const devices = values.devices?.length > 0
        values.file = invoice ?? previewImage

        if (!title) {
            setError({ message: "Erreur", description: "Veuillez entrer le Numéro de la facture" })
        } else if (!devices || devices?.length) {
            setError({ message: "Erreur", description: "Au moins un appareil est requis" })
        } else if (!values.file) {
            setError({ message: "Erreur", description: "Veuillez soumettre votre facture" })
        } else {
            setLoading(true)
            values.companyId = state?.companyId
            values.projectId = state?.projectId
            if (values.id && matched) {
                setInfo({ message: "Information", description: "Tu n'as rien changé" })
            } else {
                const createdInvoice = await addInvoice(values)
                if (createdInvoice) {
                    state.selectedInvoice = createdInvoice
                    const projectslist = [...projects]
                    const enterpriseslist = [...enterprises]
                    const selected = enterprise ? { ...enterprise } : null
                    if (values.id) {
                        const pIndex = projectslist?.findIndex(p => p.id.toString() === createdInvoice.projectId.toString())
                        const eIndex = enterpriseslist?.findIndex(e => e.id.toString() === createdInvoice.companyId.toString())
                        const index = selected.invoices?.findIndex(i => i.id.toString() === values.id.toString())

                        const pi = projectslist[pIndex].invoices.findIndex(i => i.id.toString() === values.id.toString())
                        projectslist[pIndex].invoices.splice(pi, 1, createdInvoice)
                        storeProjects([...projectslist])

                        const ei = enterpriseslist[eIndex].invoices.findIndex(i => i.id.toString() === values.id.toString())
                        enterpriseslist[eIndex].invoices.splice(ei, 1, createdInvoice)
                        setEnterprises([...enterpriseslist])

                        selected.invoices?.splice(index, 1, createdInvoice)
                        selectEnterprise({ ...selected })

                    } else {
                        for (const p of projectslist) {
                            if (p.id.toString() === createdInvoice.projectId) {
                                const invcsList = [...p.invoices]
                                invcsList.unshift(createdInvoice)
                                p.invoices = [...invcsList]
                            }
                        }
                        storeProjects([...projectslist])

                        for (const e of enterpriseslist) {
                            if (e.id.toString() === createdInvoice.companyId) {
                                const invcsList = [...e.invoices]
                                invcsList.unshift(createdInvoice)
                                e.invoices = [...invcsList]
                            }
                        }
                        setEnterprises([...enterpriseslist])

                        const invcs = [...selected.invoices]
                        invcs.unshift(createdInvoice)
                        selected.invoices = [...invcs]
                        selectEnterprise({ ...selected })
                    }
                    if (Object.hasOwnProperty.call(createdInvoice, "invoicehistories")) {
                        setHistory(createdInvoice.invoicehistories.toReversed())
                    } else {
                        closeForm()
                    }
                    setInvoiceFile(null); setFileList([])
                }
            }
            setLoading(false)
        }
    }
    const addDevice = (values) => {
        const devices = form.getFieldValue("devices");
        const current = newDevice.getFieldsValue(true);
        let d = [...devices] || []
        if (device >= 0) {
            d.splice(device, 1, current)
        } else {
            d.unshift(current)
        }
        form.setFieldsValue({ devices: d });
        setNewDevice(false);
        setDevice(-1)
    };
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
    };
    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList)
        if (newFileList.length) {
            handlePreview(newFileList[0])
        }
    };
    const closeForm = () => {
        navigate(-1);
        form.setFieldsValue(null)
    }
    const range = (start, end) => {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    };
    const disabledDateTime = () => {
        return state?.viewOnly ? {
            disabledHours: () => range(0, 24).splice(4, 20),
            disabledMinutes: () => range(30, 60),
            disabledSeconds: () => [55, 56],
        } : null
    };
    const disabledDate = (current) => {
        // Can not select days before today and today
        return state?.viewOnly ? current : null;
    };

    const capacityUnits = [
        { label: "MO", value: "mo" },
        { label: "GO", value: "go" },
        { label: "TO", value: "to" }
    ]

    return (<>
        <Row>
            <Col span={12}>
                <div className="invoice-form-header">
                    <Typography.Title
                        style={{ margin: 0, color: "#101828" }}
                        level={2}
                    >
                        Nouvelle saisie de facture
                    </Typography.Title>
                    <Typography.Title
                        level={5}
                        style={{ color: "#475467", margin: 0, fontWeight: 400 }}
                    >
                        Nouvelle facture
                    </Typography.Title>
                </div>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
                <Button
                    className="invoice-form-close-button"
                    onClick={closeForm}
                >
                    <CloseOutlined />
                </Button>
            </Col>
        </Row>
        <br />
        <Row gutter={16}>
            <Col md={12} xs={24} className={"invoice-form-left"}>
                <Typography.Title
                    style={{ marginBottom: 10, marginTop: 0, color: "#475467" }}
                    level={5}
                >
                    Affichage de la facture{" "}{title}
                </Typography.Title>

                <div className='invoice__form__upload'>
                    {
                        previewImage ? <>
                            {
                                (() => {
                                    if (fileList.length > 0) {
                                        if (fileList[0].type === "application/pdf") {
                                            console.log("if pdf");
                                            return <PDFViewerComp file={previewImage} id={state?.selectedInvoice?.id} />
                                            // return <PDFViewerReact fileUrl={previewImage} />
                                            // return <DispalayPDF fileUrl={previewImage} />
                                        } else {
                                            return <Image src={previewImage} alt={state?.selectedInvoice?.title} />
                                        }
                                    } else {
                                        if (state?.selectedInvoice?.fileType === "pdf") {
                                            console.log("else pdf", state?.selectedInvoice?.filePath);
                                            return <PDFViewerComp
                                                // file={state?.selectedInvoice?.filePath}
                                                id={state?.selectedInvoice?.id} />
                                            // return <PDFViewerReact fileUrl={state?.selectedInvoice?.filePath} />
                                            // return <DispalayPDF fileUrl={state?.selectedInvoice?.filePath} />
                                        } else {
                                            return <Image src={previewImage} alt={state?.selectedInvoice?.title} />
                                        }
                                    }
                                })()
                            }
                            {
                                (!state?.viewOnly) ? <DeleteOutlined className='del__image' onClick={() => { setPreviewImage(""); setInvoiceFile(null); setFileList([]) }} /> : null
                            }
                        </> : <Upload
                            accept=".pdf,.jpg,.png,.jpeg"
                            style={{ margin: 0 }}
                            listType="picture-card"
                            className="image-upload-grid image-upload-grid-large border-dashed"
                            onChange={handleChange}
                            fileList={fileList}
                            showUploadList={{ showPreviewIcon: false }}
                            beforeUpload={(file) => {
                                const isImage = file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'application/pdf';
                                if (!isImage) {
                                    message.error(`Le type de fichier sélectionné n'est pas pris en charge.`);
                                } else {
                                    // handleChange([file])
                                    // console.log("before", file);
                                    setInvoiceFile(file)
                                }
                                return false
                            }}
                        >
                            {fileList?.length >= 1 ? null : <div>
                                <AddButtonIcon
                                    width={34}
                                    height={34}
                                    style={{
                                        display: "block",
                                        margin: "0 auto",
                                    }}
                                />
                                Déposez ou téléchargez une ou plusieurs factures
                            </div>}

                        </Upload>
                    }
                </div>
            </Col>
            <Col md={12} xs={24} className={"invoice-form-right"}>
                <Form form={form} layout="vertical" size="large" requiredMark={false}>
                    {(values) => (
                        <>
                            <Row gutter={20}>
                                <Col xs={24} md={12}>
                                    <Form.Item name={`title`} label="Numéro de la facture" rules={[{ required: true }]} onChange={e => setTitle(e.target.value)}>
                                        <Input readOnly={state?.viewOnly} placeholder="FAC. #0000" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item name={'submitDate'} label="Date de la facture">
                                        <DatePicker allowClear={!state?.viewOnly} onChange={getEstimated} disabledDate={disabledDate} inputReadOnly={state?.viewOnly} disabledTime={disabledDateTime} style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item name={`supplier`} label="Fournisseur">
                                <Input readOnly={state?.viewOnly} placeholder="Fournisseur" />
                            </Form.Item>
                            {
                                !openDeviceForm ? <>
                                    {
                                        state?.viewOnly ? null : <Form.Item>
                                            <Button
                                                type="link"
                                                htmlType="submit"
                                                style={{
                                                    backgroundColor: "#F3ECF4",
                                                    height: 44,
                                                }}
                                                size="middle"
                                                icon={<PlusOutlined />}
                                                onClick={() => {
                                                    newDevice.resetFields();
                                                    setNewDevice(true)
                                                }}
                                            >
                                                Ajouter un appareil
                                            </Button>
                                        </Form.Item>
                                    }
                                    {values.devices?.length ? (
                                        <Table
                                            pagination={{
                                                defaultPageSize: 5,
                                                hideOnSinglePage: true
                                            }}
                                            columns={[
                                                {
                                                    key: "type",
                                                    dataIndex: "type",
                                                    title: "Type",
                                                    render: value => {
                                                        return deviceTypes.filter(d => d.value === value)[0]?.label
                                                    }
                                                },
                                                {
                                                    key: "label",
                                                    dataIndex: "label",
                                                    title: "Libellé",
                                                },
                                                {
                                                    key: "capacity",
                                                    dataIndex: "capacity",
                                                    title: "Capacité",
                                                    render: (value, item) => {
                                                        return value + " " + item.unit.toUpperCase()
                                                    }
                                                },
                                                {
                                                    key: "quantity",
                                                    dataIndex: "quantity",
                                                    title: "Quantité",
                                                },
                                                {
                                                    key: "cost",
                                                    dataIndex: "cost",
                                                    title: "CP affichée",
                                                },
                                                {
                                                    key: "action",
                                                    dataIndex: "device_type",
                                                    render: (_, row, i) => {
                                                        return state?.viewOnly ? null : <Button
                                                            type="link"
                                                            onClick={() => {
                                                                newDevice.setFieldsValue(row);
                                                                setNewDevice(true);
                                                                setDevice(i)
                                                            }}
                                                        >
                                                            <EditOutlined />
                                                        </Button>

                                                    },
                                                },
                                            ]}
                                            dataSource={values.devices}
                                        />
                                    ) : null}
                                </> : null
                            }
                        </>
                    )}
                </Form>

                {openDeviceForm ? (
                    <Form
                        form={newDevice}
                        onFinish={addDevice}
                        className="card-payment"
                        layout="vertical"
                        size="large"
                        style={{ marginTop: 0 }}
                        requiredMark={false}
                    >
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item name={"type"} rules={[{ required: true, message: "Requis!" }]} label="Type">
                                    <Select options={deviceTypes} onChange={getEstimated} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name={"label"} rules={[{ required: true, message: "Requis!" }]} label="Libellé">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Row gutter={8}>
                                    <Col span={16}>
                                        <Form.Item name={"capacity"} rules={[{ required: true, message: "Requis!" }]} label="Capacité" onChange={getEstimated}>
                                            <InputNumber style={{ width: "100%" }} min={0} onStep={getEstimated} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name={"unit"} label=" " rules={[{ required: true, message: "Requis!" }]} >
                                            <Select options={capacityUnits} onChange={getEstimated} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name={"quantity"} rules={[{ required: true, message: "Requis!" }]} label="Quantité" onChange={getEstimated}>
                                    <InputNumber min={1} style={{ width: "100%" }} onStep={getEstimated} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name={"cost"}
                                    style={{ marginBottom: 5 }}
                                >
                                    <InputNumber
                                        prefix={<>&euro;</>}
                                        style={{ width: "100%" }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name={"estimated"}
                                    style={{ marginBottom: 5 }}
                                >
                                    <InputNumber
                                        disabled
                                        prefix={<>&euro;</>}
                                        style={{ width: "100%" }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label=" " style={{ marginBottom: 5 }}>
                                    <Button htmlType="submit" block type="primary">
                                        {t("ADD")}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                ) : null}
                {
                    state?.viewOnly ? null : <Row gutter={16}>
                        <Col xs={24} md={12}>
                            <Button size='large' block onClick={closeForm}>
                                {t("CANCEL")}
                            </Button>
                        </Col>
                        <Col xs={24} md={12}>
                            <Button loading={loading} disabled={loading} size='large' block type="primary" onClick={() => saveinvoice(form.getFieldsValue(true))}>
                                {t("ADD")}
                            </Button>
                        </Col>
                    </Row>
                }

            </Col>
        </Row>
        <div className={`invoice-history${history.length === 0 ? " hide-history" : ""}`}>
            <Typography.Title
                style={{ marginBottom: 10, marginTop: 0, color: "#475467" }}
                level={5}
            >
                Historique des factures
            </Typography.Title>
            <List
                grid={{ gutter: 16, xs: 1, sm: 3, md: 4, lg: 5, xl: 8, xxl: 8 }}
                dataSource={history}
                renderItem={(item) => (
                    <List.Item>
                        {
                            item.fileType === "pdf" ? <>
                                <a className='inv__link' href={server + item.filePath} target='_blank'>
                                    <ExportOutlined className='preview' />
                                    <PDFViewerComp thumbnail={true} height={"115px"} width={"115px"} file={item.filePath} id={state?.selectedInvoice?.id} /></a>
                            </> : <div className='inv-img'><Image style={{ width: 115 }} src={item.filePath} alt={item.fileName} /></div>
                        }
                        <Typography.Paragraph>
                            {moment(item.submitDate).format("DD/MM/YYYY")}
                        </Typography.Paragraph>
                    </List.Item>
                )}
            />
        </div>
        <Row>
            <Col xs={24} md={8}>
            </Col>
        </Row>
    </>
    )
}

export default InvoiceForm
